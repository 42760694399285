<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('手动短信状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="通知类型" :label="detail.smsType|getBaseDataName('通知类型')"/>
        <ys-cell title="渠道" :label="detail.channel|getBaseDataName('短信渠道')"/>
      </template>
      <template slot="main-left">
        <Tabs type="card" v-model="tab" :key="detail.id">
          <Tab-pane label="详细信息" icon="ios-paper" name="1">
            <Info :midData="[detail]" v-if="tab==='1'"/>
          </Tab-pane>
          <Tab-pane label="发送记录" icon="ios-paper" name="2">
            <SendRecord :mid="detail.id" v-if="tab==='2'"></SendRecord>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">其他信息</h1>
        <div style="text-align: center">暂未开放！</div>
      </template>
    </ys-detail>
  </div>
</template>

<script>
import detail from '@/mixins/detail.js'
import Info from "@/views/base/notice/manual/detail/info.vue";
import SendRecord from "@/views/base/notice/manual/detail/sendRecord.vue";

export default {
  name: '',
  mixins: [detail],
  props: {},
  components: {
    Info,
    SendRecord,
  },
  data() {
    return {}
  },

  mounted() {
    this.getDetail()
  },

  methods: {
    getDetail() {
      this.loading = true;
      this.detail = {}
      this.$get('sms/manual/Info', {id: this.detailID, random:new Date().getTime()}).then((res) => {
        this.loading = false;
        this.detail = res.data;
      })
    }
  }

}
</script>

<style scoped>

</style>