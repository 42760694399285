<template>
  <div :style="{'height':height-193+'px','overflow-y':'auto'}" class="overflowY">
    <ys-cell-group>
      <ys-cell title="通知内容" :label="detail.preview" block/>
      <ys-cell title="发送时间" :label="detail.send_at"/>
      <ys-cell title="接收号码数" :label="detail.recvCount" block/>
      <ys-cell title="接收号码" :label="detail.recvNumber" block/>
      <ys-cell title="创建人" :label="detail.createdUser"/>
      <ys-cell title="创建时间" :label="detail.createdAt"/>
      <ys-cell title="备注" :label="detail.desc" block/>
    </ys-cell-group>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: '',
  props: {
    mid: {
      type: [Number, String, null],
      default: ''
    },
    midData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      detail: {}
    }
  },

  computed: {
    ...mapGetters(['height'])
  },

  mounted() {
    this.detail = this.midData[0]
  },
  methods: {}
}
</script>

<style scoped>

</style>