<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        :params="params"
        action="/article/List"
        :tableHeight="tableHeight||height-250"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import components from '@/mixins/components.js'

export default {
  name: 'projects',
  mixins: [pageList, components],
  props: {},
  data() {
    return {
      params: {
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 200, title: '通知标题', key: 'title', align: 'center'},
        {width: 80, title: '所属类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '文章所属类型'}},
        {width: 150, title: '发布机构', key: 'orgName', align: 'center'},
        {width: 150, title: '所属分类', key: 'articleTypeName', align: 'center'},
        {width: 150, title: '标签', key: 'label', align: 'left', render: (h, params) => {
          const data = params.row?.label ? JSON.parse(params.row.label) : []
          return h('div', data.map(item => h('p', item)))
        }},
        {width: 200, title: '发布时间', key: 'publishedAt', align: 'center', },
        {width: 200, title: '创建时间', key: 'createdAt', align: 'center', },
        {width: 80, title: '创建人', key: 'createdUser', align: 'center',},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: []
        },
      ]
    },
  },
  mounted() {
    this.getList()
  },
  methods: {

  }
}
</script>
