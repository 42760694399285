<template>
  <div>
    <ys-page-list
        ref="table"
        keyID="774562876B760F523B39D2BC07A9D96B"
        :multiBtn="multiBtn"
        :params="params"
        action="/sms/manual/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="通知类型" prop="smsType" show>
          <Select v-model="params.smsType" clearable placeholder="通知类型" style="width: 120px">
            <Option v-for="item in baseData['通知类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="发送状态" prop="status" show>
          <Select v-model="params.status" clearable placeholder="发送状态" style="width: 120px">
            <Option v-for="item in baseData['手动短信状态']" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改通知配置">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/notice/manual/edit.vue";
import Detail from "@/views/base/notice/manual/detail.vue";

export default {
  name: 'sendRecord',
  mixins: [pageList],
  components: {
    Edit,
    Detail
  },
  data() {
    return {
      params: {
        status: '',
        smsType: '',
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 80, title: '通知类型', key: 'smsType', align: 'center', renderConfig: {type: 'baseData', parentName: '通知类型'}},
        {width: 80, title: '渠道', key: 'channel', align: 'center', renderConfig: {type: 'baseData', parentName: '短信渠道'}},
        {minWidth: 300, title: '通知内容', key: 'preview', align: 'center',},
        {width: 100, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '手动短信状态'}},
        {width: 170, title: '发送时间', key: 'send_at', align: 'center',},
        {width: 150, title: '接收号码数', key: 'recvCount', align: 'center',},
        {width: 100, title: '创建人', key: 'createdUser', align: 'center',},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center',},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/sms/manual/Del', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 40
            },
          ]
        },
        {
          children: [
            {
              click: () => this.send(),
              title: '发送',
              icon: 'ios-radio-outline',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 40
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.templateId = this.mid || ''
    this.getList();
  },
  methods: {
    send() {
      this.$post('/sms/manual/Send', {id: this.tableSelectItem[0].id}).then(res => {
        if(res.code === 200) {
          this.$Notice.success({
            title: '发送成功！',
          });
          this.getList();
        }
      })
    }
  }
}
</script>

<style>

</style>