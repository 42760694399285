<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100" inline>
      <FormItem label="通知类型" prop="smsType">
        <Select v-model="form.smsType" clearable :disabled="true" transfer placeholder="通知类型">
          <Option v-for="item in baseData['通知类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="渠道" prop="channel">
        <Select v-model="form.channel" clearable :disabled="true" transfer placeholder="渠道">
          <Option v-for="item in baseData['短信渠道']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="通知模板" prop="smsTemplateId" style="width: 100%;">
        <Select v-model="form.smsTemplateId" clearable @on-change="smsTemplate" transfer placeholder="通知模板">
          <Option v-for="item in smsTemplateList" :value="item.id" :key="item.id">{{ item.title }}</Option>
        </Select>
      </FormItem>
      <template v-if="articleFlag">
        <FormItem label="预警信息" prop="articleName" style="width: 50%;">
          <Input v-model="form.articleName" disabled placeholder="预警信息" style="width: 80%;"></Input>
          <Button type="primary" @click="warningInfoFlag=true" style="width: 20%;">选择</Button>
        </FormItem>
        <FormItem label="发起平台" prop="platformId">
          <Select v-model="form.platformId" clearable @on-change="platformChange" transfer placeholder="通知模板">
            <Option v-for="item in platformList" :value="item.id" :key="item.id">{{ item.host }}</Option>
          </Select>
        </FormItem>
      </template>
      <FormItem :label="item.title" :prop="'manualDescList.' + index + '.value'" :rules="{required: true, message: (isDataPicker(item) ? '请选择' : '请填写') + item.title, trigger: 'blur'}" v-for="(item, index) in form.manualDescList" :key="index">
        <!-- 时间选择模式 -->
        <ys-date-picker type="datetime" style="width: 100%;" :placeholder="item.title" v-model="item.value" @on-change="smsManualChange($event, item)" :disabled="item.disabled" v-if="isDataPicker(item)"/>
        <!-- 输入框模式 -->
        <Input v-model="item.value" @on-change="smsManualChange($event, item)" :disabled="item.disabled" v-else></Input>
      </FormItem>
      <FormItem label="短信预览" prop="preview" style="width: 100%">
        <Input v-model="form.preview" readonly type="textarea" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 10}" placeholder="短信预览"></Input>
      </FormItem>
      <FormItem label="接收号码" prop="recvNumber" style="width: 100%">
        <Input v-model="form.recvNumber" :disabled="isdisabled" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 5,maxRows: 10}" placeholder="多个接收人请用英文;分开！"></Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 10}" placeholder="备注"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="warningInfoFlag" :width="1200" title="预览">
      <warning-info @on-ok="getWarningInfo"></warning-info>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import warningInfo from "_c/warningInfo";

export default {
  name: 'configureEdit',
  components: {
    warningInfo
  },
  mixins: [edit],
  props: {},
  data() {
    return {
      ruleValidate: {
        smsType: [
          {required: true, type: 'number', message: '请选择所属分类', trigger: 'change'}
        ],
        channel: [
          {required: true, type: 'number', message: '请选择渠道', trigger: 'change'}
        ],
        smsTemplateId: [
          {required: true, type: 'number', message: '请选择通知模板', trigger: 'change'}
        ],
        articleName: [
          {required: true, type: 'string', message: '请选择预警信息', trigger: 'change'}
        ],
        recvNumber: [
          {required: true, trigger: 'change', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请填写接收号码!'));
              } else {
                const flag = value.split(';').every(item => {
                  return this.$reg.phoneReg.reg.test(item)
                })
                if (!flag) {
                  // this.$reg.phoneReg.tips
                  callback(new Error('请检查填写的手机号码格式是否全部正确！'))
                  return
                }
                callback()
              }
            }
          }
        ],
        platformId: [
          {required: true, message: '请选择预平台', trigger: 'change', type: 'number'}
        ],
      },
      form: {
        id: '',
        smsType: 1,
        channel: 1,
        smsTemplateId: null,
        manualDescList: [],
        manualDesc: '',
        platformId: '',
        recvNumber: '',
        preview: '',
        desc: '',
        articleName: '',
        articleId: '',
      },
      isdisabled: false,
      warningInfoFlag: false,
      articleFlag: false, // 选择极端天气文章开关
      platformList: [],
      smsTemplateList: [],
    }
  },
  computed: {
    isDataPicker() {
      // 通过判断value（即键值名）中是否含有关键词段
      const List = ['时间', '日期']
      return (item) => {
        return (isNaN(item.value) && !isNaN(Date.parse(item.value))) || List.some(ite => item.title.indexOf(ite) !== -1)
      }
    }
  },
  async mounted() {
    await this.getSmsTemplateList()
    await this.getPlatformIdList()
    if (this.mid) {
      this.$get('/sms/manual/Info', {id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.form.manualDescList = JSON.parse(res.data.manualDesc)
      })
    }
  },
  methods: {
    platformChange() {
      this.form.manualDescList[2].value = btoa(encodeURI(this.form.platformId + '-/#/notice/' + this.form.articleId));
      this.smsManualChange()
    },
    async getPlatformIdList() {
      let host = window.location.host.indexOf('localhost') >= 0 ? 'adm-test.us-smart.cn' : window.location.host;
      await this.$get('https://dc-test.us-smart.cn/api/platform/GetByHost', {type: 2, host: host, limit: -1}).then(res => {
        if (res.code === 200) {
          this.platformList = res.data.list || [];
          this.form.platformId = this.platformList.length > 0 ? this.platformList[0].id : '';
        }
      })
    },

    async getSmsTemplateList() {
      await this.$get('/sms/template/List', {isManual: 1, limit: -1}).then(res => {
        if (res.code === 200 && res.data.list && res.data.list.length > 0) {
          this.smsTemplateList = res.data.list
          // 默认选中0下标
          this.smsTemplate(this.smsTemplateList[0]?.id || '')
        }
      })
    },
    smsTemplate(data) {
      const smsTemplate = this.smsTemplateList.filter(item => item.id === data)[0]
      this.articleFlag = smsTemplate?.action === 'extremeWeather'
      this.form.smsTemplateId = smsTemplate?.id || null
      this.form.manualDescList = smsTemplate?.manualDesc ? JSON.parse(smsTemplate.manualDesc) : []
      this.form.preview = smsTemplate?.preview || ''
    },
    // 更改模板内容对应内容自动同步到短信预览中
    smsManualChange() {
      // 取出原始串,依次替换,赋值
      let preview = this.smsTemplateList.filter(item => item.id === this.form.smsTemplateId)[0].preview
      this.form.manualDescList.forEach(item => {
        // 无值的暂时先用%a替换,后面再全换回来
        preview = preview.replace("%s", item.value || '%a')
      })
      this.form.preview = preview.replace(/%a/g, '%s')
    },
    // 获取极端天气预警文章信息的uuid值，获取到的uuid需要放到模板对应的位置
    getWarningInfo(data) {
      this.form.articleName = data[0].title
      this.form.articleId = data[0].id
      this.form.manualDescList[2].value = btoa(encodeURI(this.form.platformId + '-/#/notice/' + this.form.articleId));
      this.smsManualChange()
    },
    save() {
      this.form.manualDesc = JSON.stringify(this.form.manualDescList)
      this.ok('/sms/manual/AddOrEdit');
    }
  }
}
</script>

<style lang="less" scoped>

</style>


