<template>
  <div>
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        :params="params"
        action="/sms/log/List"
        :tableHeight="resultHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="通知类型" prop="smsType" show>
          <Select v-model="params.smsType" clearable placeholder="通知类型" style="width: 120px">
            <Option v-for="item in baseData['通知类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="发送状态" prop="status" show>
          <Select v-model="params.status" clearable placeholder="发送状态" style="width: 120px">
            <Option :value="1">成功</Option>
            <Option :value="2">失败</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: '',
  mixins: [pageList],
  components: {},
  data() {
    return {
      params: {
        status: '',
        smsType: '',
        smsManualId: '',
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 80, title: '通知类型', key: 'smsType', align: 'center', renderConfig: {type: 'baseData', parentName: '通知类型'}},
        {width: 80, title: '渠道', key: 'channel', align: 'center', renderConfig: {type: 'baseData', parentName: '短信渠道'}},
        {minWidth: 250, title: '通知内容', key: 'content', align: 'center',},
        {width: 60, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '发送状态'}},
        {width: 170, title: '发送时间', key: 'sendAt', align: 'center',},
        {width: 150, title: '接收人所在机构', key: 'receiverOrg', align: 'center',},
        {width: 130, title: '接收人', key: 'receiver', align: 'center',},
        {width: 150, title: '手机号', key: 'phone', align: 'center',},
        {minWidth: 150, title: '失败原因', key: 'error', align: 'left',},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center',},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: []
        },
      ]
    },
  },
  mounted() {
    this.params.smsManualId = this.mid || ''
    this.getList();
  },
  methods: {}
}
</script>

<style>

</style>